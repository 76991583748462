import { Row, Col, Button } from "react-bootstrap";

import { CONTRACT_ADDRESS as contract_MPG } from "@/contracts/tokens/MPG";
import { CONTRACT_ADDRESS as contract_IGLOO } from "@/contracts/tokens/IGLOO";

import { Logo } from "@/components/atoms/Logo/Logo.tsx";
import { Description } from "@/components/atoms/Description/Description.tsx";

const title = process.env.REACT_APP_APP_TITLE;
const desc = process.env.REACT_APP_APP_DESCRIPTION;

const tokens = [
  {
    ticker: "MPG",
    contract: contract_MPG,
  },
  {
    ticker: "IGLOO",
    contract: contract_IGLOO,
  },
];

export default function Home() {
  return (
    <div className="text-center">
      <Logo text={title} />
      <Description text={desc} />
      <Row className="justify-content-center mt-1 g-5">
        {tokens.map((token, i) => (
          <Col key={token.ticker} xs="auto">
            <Button
              as="a"
              key={i}
              target="_blank"
              variant="outline-light"
              rel="noopener noreferrer nofollow"
              href={process.env.REACT_APP_UNISWAP_BUY_URL + token.contract}
            >
              BUY {token.ticker}
            </Button>
          </Col>
        ))}
      </Row>
    </div>
  );
}
