import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "@/scss/styles.scss";

import cx from "classnames";

import { lazy, useEffect, useContext, Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import { AnimatePresence } from "framer-motion";

import { logConsoleMessage } from "@/utils/misc/logConsoleMessage";
import TransitionWrapper from "@/TransitionWrapper";

import Header from "@/components/layout/Header/Header";
import Footer from "@/components/layout/Footer";
import Main from "@/components/layout/Main";

import Aside from "@/components/layout/Aside/Aside";

import Modal from "@/components/organisms/Web3/Modal";
import Snow from "@/components/effects/Snow";

import Home from "@/routes/Home";
// import NotFound from '@/routes/NotFound'
const Freeze = lazy(() => import("@/routes/Freeze"));
const Stake = lazy(() => import("@/routes/Stake"));
const Lockers = lazy(() => import("@/routes/Lockers"));
const Developers = lazy(() => import("@/routes/Developers"));
const NotFound = lazy(() => import("@/routes/NotFound"));

import { AsideContext } from "@/context/AsideContext";
import { useMedia } from "@/hooks/useMedia";

function App() {
  useEffect(() => {
    logConsoleMessage();
  }, []);

  const [hasAside] = useContext(AsideContext);
  const isMobile = useMedia("(max-width: 991px)");

  const location = useLocation();

  const routes = [
    {
      title: "Home",
      element: <Home />,
    },
    {
      path: "stake",
      title: "Stake",
      element: <Stake />,
    },
    {
      path: "lockers",
      title: "Lockers",
      element: <Lockers />,
    },
    {
      path: "freeze",
      title: "Freeze",
      element: <Freeze />,
    },
    {
      path: "developers",
      title: "Developers",
      element: <Developers />,
    },
    {
      path: "*",
      title: "Not found",
      element: <NotFound />,
    },
  ];

  return (
    <div
      className={cx(
        "app",
        hasAside ? "has-aside" : undefined,
        isMobile ? "is-mobile" : undefined
      )}
    >
      <Header />
      <Main>
        <AnimatePresence mode="wait">
          <Routes key={location.pathname} location={location}>
            {routes.map((route, i) => (
              <Route
                key={i}
                index={!route.path}
                path={route.path}
                element={
                  <TransitionWrapper>
                    <Suspense fallback={<></>}>{route.element}</Suspense>
                  </TransitionWrapper>
                }
              />
            ))}
          </Routes>
        </AnimatePresence>
      </Main>
      <Footer />

      <Aside />
      <ToastContainer
        position="bottom-right"
        theme="dark"
        newestOnTop
        limit={3}
      />
      <Modal />
      <Snow />
    </div>
  );
}

export default App;
