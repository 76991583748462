// wagmi
import { configureChains, mainnet, goerli } from "wagmi";

// wagmi - chains
import {
  arbitrum,
  avalanche,
  bsc,
  evmos,
  fantom,
  filecoin,
  gnosis,
  metis,
  optimism,
  polygon,
  sepolia,
  zkSync,
} from "wagmi/chains";

// wagmi - providers
import { alchemyProvider } from "wagmi/providers/alchemy";
import { infuraProvider } from "wagmi/providers/infura";
import { publicProvider } from "wagmi/providers/public";

const alchemyApiKey = process.env.REACT_APP_ALCHEMY_API_KEY;
const infuraApiKey = process.env.REACT_APP_INFURA_API_KEY;

const supportedChains = [
  mainnet,
  goerli,
  arbitrum,
  avalanche,
  bsc,
  evmos,
  fantom,
  filecoin,
  gnosis,
  metis,
  optimism,
  polygon,
  sepolia,
  zkSync,
];

export const { chains, provider, webSocketProvider } = configureChains(
  supportedChains,
  [
    alchemyProvider({
      apiKey: alchemyApiKey || "",
      priority: 0,
    }),
    infuraProvider({
      apiKey: infuraApiKey || "",
      priority: 1,
    }),
    publicProvider({
      priority: 2,
    }),
  ]
  //   { targetQuorum: 2 }
);
