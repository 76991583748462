import { useState, useContext, useEffect } from "react";

import { Modal, Button, Row, Col, Badge } from "react-bootstrap";

import {
  mainnet,
  useDisconnect,
  useSwitchNetwork,
  useBlockNumber,
} from "wagmi";

import { changeAccounts } from "@/utils/changeAccounts";
import { formatAmount } from "@/utils/format/formatAmount";
import { formatAddress } from "@/utils/format/formatAddress";

import { UserContext } from "@/context/UserContext";

import styles from "./Account.module.scss";

function Account() {
  const { disconnect } = useDisconnect();
  const { switchNetwork } = useSwitchNetwork();

  const [transactions, setTransactions] = useState();
  const [hasDialog, setHasDialog] = useState(false);

  const { user } = useContext(UserContext);

  // define shorthands
  const account = user.account;
  const network = user.network;
  const balance = user.balance;

  const chain = network.chain;
  const isEth = chain.id === mainnet.id;

  useEffect(() => {
    function checkTransactions() {
      const transactions = JSON.parse(localStorage.getItem("transactions"));
      if (transactions) setTransactions(transactions);
      console.dir(transactions);
    }
    checkTransactions();

    window.addEventListener("storage", checkTransactions);

    return () => {
      window.removeEventListener("storage", checkTransactions);
    };
  }, []);

  const clearTransactions = () => {
    localStorage.removeItem("transactions");
    setTransactions();
  };

  const closeDialog = ({ confirm }) => {
    if (confirm === true) clearTransactions();
    setHasDialog(false);
  };

  const { data: latestBlock } = useBlockNumber({
    cacheTime: 2000,
    staleTime: 2000,
    watch: true,
    chainId: network?.chain?.id,
  });

  return (
    <>
      <div className={`${styles.account} ${hasDialog ? styles.hasDialog : ""}`}>
        <div className="mb-2 pb-4 border-bottom">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="fw-bold">Wallet</h3>
          </div>
          <span className="d-flex justify-content-between">
            <span>Address</span>
            <span>
              {formatAddress(account?.address)}
              &nbsp;
              <span
                className="text-muted"
                role="button"
                onClick={() => changeAccounts()}
              >
                (change)
              </span>
            </span>
          </span>
          <span className="d-flex justify-content-between">
            <span>Balance</span>
            <span className={chain?.unsupported ? "text-muted" : undefined}>
              {!chain?.unsupported
                ? `${formatAmount(balance.ETH.data?.formatted)} ${
                    chain.nativeCurrency.symbol
                  }`
                : "Unknown"}
            </span>
          </span>
        </div>
        <div className="pt-2">
          <h3 className="fw-bold d-block">Network</h3>
          <span className="d-flex justify-content-between">
            <span>Name</span>
            <span className={chain?.unsupported ? "text-muted" : undefined}>
              {!chain?.unsupported ? chain.name : "Unknown"}
            </span>
          </span>
          <span className="d-flex justify-content-between">
            <span>Currency</span>
            <span className={chain?.unsupported ? "text-muted" : undefined}>
              {!chain?.unsupported ? chain.nativeCurrency.symbol : "Unknown"}
            </span>
          </span>
          <span className="d-flex justify-content-between">
            <span>Latest block</span>
            <span className={chain?.unsupported ? "text-muted" : undefined}>
              {!chain.unsupported ? latestBlock : "Unknown"}
            </span>
          </span>

          {!isEth && (
            <div className="mt-4">
              <small
                role="button"
                className={`text-muted fw-light`}
                onClick={() => switchNetwork(1)}
              >
                Unsupported network, please connect to&nbsp;
                <span className="fw-normal">Ethereum</span>.
              </small>
            </div>
          )}
        </div>

        <div className="mt-4">
          <span className="d-flex justify-content-between">
            Recent transactions&nbsp;
            <Badge bg="dark">
              <small>Coming soon!</small>
            </Badge>
          </span>
          <small className="d-block">
            {!transactions ? (
              <i className="text-muted">No recent transactions found.</i>
            ) : (
              <>
                {transactions?.map((transaction, i) => (
                  <span
                    key={i}
                    className="text-muted d-flex justify-content-between"
                  >
                    <span>{transaction.type}</span>
                    <span>{transaction.date}</span>
                    <span>{transaction.hash}</span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      className="text-decoration-underline"
                      href={`https://etherscan.io/tx/${transaction.hash}`}
                    >
                      Etherscan
                    </a>
                  </span>
                ))}
                <small
                  role="button"
                  className="d-block mt-2"
                  onClick={() => setHasDialog(true)}
                >
                  Clear transactions
                </small>
              </>
            )}
          </small>
        </div>

        <div className="mt-4">
          <span role="button" className="d-inline-block" onClick={disconnect}>
            Disconnect
          </span>

          <small className="d-block text-muted">
            Some wallets don&apos;t allow us to programmatically disconnect. In
            these cases you can disconnect from within your wallet&apos;s
            interface.
          </small>
        </div>
      </div>

      <Modal
        centered
        show={hasDialog}
        className="dialog"
        onHide={() => setHasDialog(false)}
        // style={{backgroundColor: 'transparent' }}
      >
        <Modal.Body className="p-4">
          <p className="mb-2">
            This will clear your latest transactions from local storage.
          </p>
          <small className="d-block text-muted">
            Doing so will remove them from your transaction overview in our
            dApp, but do not worry - your transactions will forever remain on
            the blockchain and can always be found on&nbsp;
            <a
              href={`https://etherscan.io/address/${account.address}`}
              className="text-decoration-underline"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              Etherscan
            </a>
            .
          </small>
          <Row className="g-4 mt-2">
            <Col xs="auto">
              <Button onClick={() => closeDialog({ confirm: true })}>
                Confirm
              </Button>
            </Col>
            <Col xs="auto">
              <Button onClick={() => closeDialog({ confirm: false })}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Account;
