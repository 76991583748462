import styles from "./Socials.module.scss";

import { useState, useEffect } from "react";

import { motion } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faTelegram } from "@fortawesome/free-brands-svg-icons";

import Tooltip from "@/components/molecules/Tooltip";

import gitbookLogo from "@/assets/logos/gitbook.svg";

const socials = [
  {
    label: "Twitter",
    icon: faTwitter,
    href: "https://twitter.com/Penguin_MPG",
  },
  {
    label: "Telegram",
    icon: faTelegram,
    href: "https://t.me/MurderPenguin",
  },
];

const gitbook = {
  href: "https://the-tale-of-francois.gitbook.io/",
  icon: gitbookLogo,
};

function Socials() {
  const [isImageLoaded, setIsImageLoaded] = useState();

  useEffect(() => {
    const image = new window.Image();
    image.src = gitbook.icon;
    image.onload = () => setIsImageLoaded(true);
  }, [gitbook.icon]);

  return (
    <>
      {isImageLoaded && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          {socials.map((item, i) => (
            <div className={styles.item} key={i}>
              <Tooltip content={item.label} position="top">
                <a
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <FontAwesomeIcon icon={item.icon} size="lg" />
                </a>
              </Tooltip>
            </div>
          ))}
          <div className={styles.item + " " + styles.gitbook}>
            <Tooltip content="Gitbook" position="top">
              <a
                href={gitbook.href}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <img src={gitbook.icon} alt="Gitbook" width="26" height="26" />
              </a>
            </Tooltip>
          </div>
        </motion.div>
      )}
    </>
  );
}

export default Socials;
