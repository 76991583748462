import styles from "./Header.module.scss";

import cx from "classnames";
import { NavLink } from "react-router-dom";
import { Container } from "react-bootstrap";

import Nav from "@/components/organisms/Nav/Nav";
import Connect from "@/components/organisms/Web3/Connect/Connect";
import Toggle from "@/components/layout/Aside/Toggle";

import { useMedia } from "@/hooks/useMedia";

const Header: React.FC = () => {
  const isMobile = useMedia("(max-width: 991px)");

  return (
    <header className={styles.header}>
      <Container className="d-flex justify-content-between align-items-center">
        <NavLink to="/" className={cx(styles.logo, "me-lg-5")} end>
          <h1>ICEBOX</h1>
        </NavLink>

        {!isMobile ? (
          <>
            <Nav />
            <Connect />
          </>
        ) : (
          <Toggle />
        )}
      </Container>
    </header>
  );
};

export default Header;
