import styles from "./Toggle.module.scss";

import { useContext } from "react";
import cx from "classnames";

import { AsideContext } from "@/context/AsideContext";

export const Toggle: React.FC = () => {
  const [hasAside, toggleAside] = useContext(AsideContext);

  return (
    <div
      className={cx(styles.toggle, hasAside ? styles.close : undefined)}
      onClick={() => toggleAside()}
    >
      {Array(4)
        .fill(0)
        .map((_, i) => (
          <span key={i}></span>
        ))}
    </div>
  );
};

export default Toggle;
