import styles from "./Nav.module.scss";

import { NavLink } from "react-router-dom";

import cx from "classnames";

type NavProps = {
  aside?: boolean;
};

const items = [
  {
    to: "/stake",
    label: "STAKE",
  },
  {
    to: "/freeze",
    label: "FREEZE",
  },
  {
    to: "/lockers",
    label: "LOCKERS",
  },
  {
    to: "/developers",
    label: "DEVELOPERS",
    disabled: true,
  },
];

const Nav = ({ aside }: NavProps): JSX.Element => {
  // const [toggleAside] = useContext(AsideContext);

  return (
    <div className={cx(styles.nav, aside ? styles["is-aside"] : "")}>
      {items.map((item) => (
        <NavLink
          to={item.to}
          key={item.to}
          className={({ isActive }) =>
            cx(
              styles["nav-item"],
              isActive ? styles.active : undefined,
              item.disabled ? styles.disabled : undefined
            )
          }
          onClick={(e) => item.disabled && e.preventDefault()}
          //   onClick={(e) => {
          //     if (item.disabled) {
          //       e.preventDefault();
          //     }
          //     if (aside) {
          //       toggleAside();
          //     }
          //   }}
        >
          <span>{item.label}</span>
        </NavLink>
      ))}
    </div>
  );
};

export default Nav;
