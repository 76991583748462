import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const LockerContext = createContext();

export const LockerContextProvider = ({ children }) => {
  const [lockers, setLockers] = useState([]);

  const providerValue = [lockers, setLockers];

  return (
    <LockerContext.Provider value={providerValue}>
      {children}
    </LockerContext.Provider>
  );
};

LockerContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
