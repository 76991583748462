// wagmi
import { createClient } from "wagmi";

// wagmi - config
import { chains, provider, webSocketProvider } from "./config";

// wagmi - connectors
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
// import { InjectedConnector } from "wagmi/connectors/injected";

export const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({
      chains,
    }),

    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: process.env.REACT_APP_APP_TITLE || "",
        darkMode: true,
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
    // new InjectedConnector({
    //   chains
    // }),
  ],
  provider,
  webSocketProvider,
});
