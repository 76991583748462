import { useAccount } from "wagmi";
import { formatAddress } from "@/utils/format/formatAddress";

type AddressProps = {
  full?: boolean;
};

export const Address = ({ full = false }: AddressProps) => {
  const { address } = useAccount();

  return !full ? formatAddress(address) : address;
};
