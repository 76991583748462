export const config = {
  unmountDelay: 400,
  updateInterval: 5000,

  flakeCount: 75,
  minFlakeSpeed: 0.0,
  maxFlakeSpeed: 0.3,
  minWindSpeed: 0.0,
  maxWindSpeed: 0.3,
  minOpacity: 0.2,
  maxOpacity: 0.4,
};
