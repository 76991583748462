// React
import { createContext } from "react";

// PropTypes
import PropTypes from "prop-types";

// Wagmi
import {
  mainnet,
  useAccount,
  useNetwork,
  useBalance,
  useContractRead,
} from "wagmi";

// Contracts
import { CONTRACT_ADDRESS as contract_MPG } from "@/contracts/tokens/MPG";

import {
  CONTRACT_ADDRESS as contract_IGLOO,
  CONTRACT_ABI as abi_IGLOO,
} from "@/contracts/tokens/IGLOO";

import {
  CONTRACT_ADDRESS as contract_xIGLOO,
  CONTRACT_ABI as abi_xIGLOO,
} from "@/contracts/tokens/xIGLOO";

import {
  CONTRACT_ADDRESS as contract_STAKING,
  CONTRACT_ABI as abi_STAKING,
} from "@/contracts/staking";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const account = useAccount();
  const network = useNetwork();

  // const [rewards, setRewards] = useState({ pending: {}, claimed: {} });
  // const [alowance, setAllowance] = useState({ IGLOO: {}, xIGLOO: {} });

  // shared config
  const useBalanceConfig = {
    address: account.address,
    chainId: mainnet.id,
    watch: true,
  };
  const useContractReadConfig = {
    chainId: mainnet.id,
    cacheOnBlock: true,
    watch: true,
    structuralSharing: (prev, next) => (prev === next ? prev : next),
  };

  // balance
  const balance = {
    ETH: useBalance({
      ...useBalanceConfig,
    }),
    MPG: useBalance({
      ...useBalanceConfig,
      token: contract_MPG,
    }),
    IGLOO: useBalance({
      ...useBalanceConfig,
      token: contract_IGLOO,
    }),
    xIGLOO: useBalance({
      ...useBalanceConfig,
      token: contract_xIGLOO,
    }),
  };

  // allowance
  const allowanceConfig = {
    functionName: "allowance",
    args: [account.address, contract_xIGLOO],
  };
  const allowance = {
    IGLOO: useContractRead({
      ...useContractReadConfig,
      ...allowanceConfig,
      address: account.isConnected ? contract_IGLOO : null,
      abi: abi_IGLOO,
    }),
    xIGLOO: useContractRead({
      ...useContractReadConfig,
      ...allowanceConfig,
      address: account.isConnected ? contract_xIGLOO : null,
      abi: abi_xIGLOO,
    }),
  };

  // rewards
  const rewardsConfig = {
    address: account.isConnected ? contract_STAKING : null,
    abi: abi_STAKING,
    args: [account.address],
  };

  const rewards = {
    pending: useContractRead({
      ...useContractReadConfig,
      ...rewardsConfig,
      functionName: "getPending",
    }),
    claimed: useContractRead({
      ...useContractReadConfig,
      ...rewardsConfig,
      functionName: "getTotalRewardsToUser",
    }),
  };

  const providerValue = {
    user: {
      account,
      network,
      balance,
      allowance,
      rewards,
    },
  };

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
