import styles from "./Pulse.module.scss";

import { useBlockNumber } from "wagmi";

function Pulse() {
  const { status } = useBlockNumber();

  return (
    <span className={styles.wrapper}>
      <i className={`${styles.pulse} ${styles[status]}`} />
    </span>
  );
}

export default Pulse;
