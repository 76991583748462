import { createContext, useState, useMemo } from "react";

import PropTypes from "prop-types";

export const ModalContext = createContext();

export const ModalContextProvider = ({ children }) => {
  const [hasModal, setModal] = useState(false);

  const providerValue = useMemo(
    () => ({
      hasModal,
      setModal,
    }),
    [hasModal]
  );

  return (
    <ModalContext.Provider value={providerValue}>
      {children}
    </ModalContext.Provider>
  );
};

ModalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
