import Snowfall from "react-snowfall";
import styles from "./Snow.module.scss";

import { useState, useEffect } from "react";

import { config } from "./config";

import { useMedia } from "@/hooks/useMedia";

function useDelayUnmount(isMounted, delay) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isMounted && !isVisible) {
      setIsVisible(true);
    } else if (!isMounted && isVisible) {
      timeoutId = setTimeout(() => setIsVisible(false), delay);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delay, isVisible]);

  return isVisible;
}

function Snow() {
  const flakeCount = config.flakeCount;

  const [isMounted, setIsMounted] = useState(true);
  const isVisible = useDelayUnmount(isMounted, config.unmountDelay);

  const [flakeSpeed, setFlakeSpeed] = useState([]);
  const [windSpeed, setWindSpeed] = useState([]);

  const [opacity, setOpacity] = useState(0.2);

  const isMobile = useMedia("(max-width: 991px)");

  const mountedStyle = {
    animation: `fadeIn ${config.unmountDelay}ms ease-in`,
  };
  const unmountedStyle = {
    animation: `fadeOut ${config.unmountDelay}ms ease-out`,
    animationFillMode: "forwards",
  };

  const [snowfallStyle, setSnowfallStyle] = useState({
    opacity,
    transition: "opacity 1s ease-in-out",
  });

  const toggleSnow = () => {
    setIsMounted((prevVal) => !prevVal);
  };

  const getRandomVal = (min, max) => {
    return (Math.random() * (max - min) + min).toFixed(1);
  };

  const getMinMaxArr = (min, max) => {
    const [val1, val2] = [min, max].map(() =>
      (Math.random() * (max - min) + max).toFixed(1)
    );
    return [Math.min(val1, val2), Math.max(val1, val2)];
  };

  const updateSpeeds = () => {
    setFlakeSpeed(getMinMaxArr(config.minFlakeSpeed, config.maxFlakeSpeed));
    setWindSpeed(getMinMaxArr(config.minWindSpeed, config.maxWindSpeed));
  };

  const updateOpacity = () => {
    setOpacity(getRandomVal(config.minOpacity, config.maxOpacity));
  };

  const updateValues = () => {
    updateSpeeds();
    updateOpacity();
  };

  useEffect(() => {
    let timeoutId = setTimeout(() => updateValues(), config.updateInterval);
    return () => clearTimeout(timeoutId);
  });

  useEffect(() => {
    setSnowfallStyle((prevSnowfallStyle) => ({
      ...prevSnowfallStyle,
      opacity,
    }));
  }, [opacity]);

  useEffect(() => {
    updateValues();
  }, []);

  return (
    <div className={styles.wrapper}>
      {isVisible && (
        <div style={isMounted ? mountedStyle : unmountedStyle}>
          <Snowfall
            wind={windSpeed}
            speed={flakeSpeed}
            style={snowfallStyle}
            snowflakeCount={flakeCount}
          />
        </div>
      )}
      {!isMobile && (
        <div className={styles.toggle} onClick={() => toggleSnow()}>
          <span
            className={[styles.button, isMounted && styles.pause].join(" ")}
          />
        </div>
      )}
    </div>
  );
}

export default Snow;
