import styles from "./Logo.module.scss";
import ice from "@/assets/textures/ice.webp";

type LogoProps = {
  text: string;
};

export const Logo = ({ text }: LogoProps): JSX.Element => (
  <h1
    data-heading={text}
    className={styles.heading}
    style={{ backgroundImage: `url(${ice})` }}
  >
    {text}
  </h1>
);
