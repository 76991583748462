import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

// Router-dom
import { BrowserRouter as Router } from "react-router-dom";

// Context
import { ModalContextProvider } from "@/context/ModalContext";
import { UserContextProvider } from "@/context/UserContext";
import { LockerContextProvider } from "@/context/LockerContext";
// Wagmi
import { WagmiConfig } from "wagmi";
import { client } from "./wagmi/client";

// Buffer
import { Buffer } from "buffer";

import App from "./App";
import { AsideContextProvider } from "./context/AsideContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

// TS:
// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );

// window.Buffer = window.Buffer || require("buffer").Buffer;

window.Buffer = window.Buffer || Buffer;

root.render(
  // <React.StrictMode>
  <WagmiConfig client={client}>
    <AsideContextProvider>
      <UserContextProvider>
        <LockerContextProvider>
          <ModalContextProvider>
            <Router>
              <App />
            </Router>
          </ModalContextProvider>
        </LockerContextProvider>
      </UserContextProvider>
    </AsideContextProvider>
  </WagmiConfig>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
