import { useState, useEffect } from "react";
import styles from "./Tooltip.module.scss";

import PropTypes from "prop-types";

import cx from "classnames";

function useDelayUnmount(isMounted, delayTime) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isMounted && !isVisible) {
      setIsVisible(true);
    } else if (!isMounted && isVisible) {
      timeoutId = setTimeout(() => setIsVisible(false), delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, isVisible]);

  return isVisible;
}

const Tooltip = (props) => {
  const [isMounted, setIsMounted] = useState(false);
  const isVisible = useDelayUnmount(isMounted, 200);

  const mountedStyle = {
    animation: "fadeIn 200ms ease-in",
  };
  const unmountedStyle = {
    animation: "fadeOut 200ms ease-out",
    animationFillMode: "forwards",
  };

  return (
    <div
      className={styles.wrapper}
      onMouseEnter={() => setIsMounted(true)}
      onMouseLeave={() => setIsMounted(false)}
    >
      {props.children}
      {isVisible && (
        <div
          style={isMounted ? mountedStyle : unmountedStyle}
          className="user-select-none"
        >
          <div
            className={cx(
              styles.tooltip,
              styles[props.position],
              styles[props.color]
            )}
          >
            {props.content}
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  position: PropTypes.string,
  color: PropTypes.string,
};
