export function formatAmount(amount?: string): string | undefined {
  if (!amount) return;

  const res = amount.split(".");
  const int = res[0];
  const dec = res[1];

  if (res.length === 1) return int;
  else return int + "." + dec.slice(0, 5).padEnd(5, "0");
}
