import styles from "./Aside.module.scss";

import { useContext, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { useMatch } from "react-router-dom";

import Nav from "@/components/organisms/Nav/Nav";
import Connect from "@/components/organisms/Web3/Connect/Connect";

import { AsideContext } from "@/context/AsideContext";

import { useMedia } from "@/hooks/useMedia";

const Aside: React.FC = () => {
  const [hasAside, setHasAside] = useContext(AsideContext);

  const isMobile = useMedia("(max-width: 991px)");

  const match = useMatch("*");

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => setHasAside(false), 250);
    }
  }, [match]);

  return (
    <>
      {isMobile && (
        <AnimatePresence>
          {hasAside && (
            <motion.aside
              className={styles.aside}
              initial={{ x: "-100%" }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: "-100%" }}
              transition={{ duration: 0.5, type: "tween" }}
            >
              <Nav aside />
              <Connect />
            </motion.aside>
          )}
        </AnimatePresence>
      )}
    </>
  );
};

export default Aside;
