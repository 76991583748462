import { useNetwork, useBlockNumber } from "wagmi";

import PropTypes from "prop-types";

export function BlockNumber({ chainId }) {
  const { chain } = useNetwork();

  const { data: latestBlock } = useBlockNumber({
    cacheTime: 2000,
    staleTime: 2000,
    watch: true,
    chainId,
  });

  return (
    <>{!chain?.unsupported || chainId === 1 ? latestBlock : "Unavailable"}</>
  );
}

BlockNumber.propTypes = {
  chainId: PropTypes.number,
};
