// import { motion } from "framer-motion";

import { m, LazyMotion, domAnimation } from "framer-motion";

import PropTypes from "prop-types";

const transitionConfig = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.25 },
};

const Transition = ({ children }) => {
  return (
    <LazyMotion features={domAnimation}>
      <m.div
        variants={transitionConfig}
        transition="duration"
        initial="initial"
        animate="animate"
        exit="exit"
      >
        {children}
      </m.div>
    </LazyMotion>
  );
};
export default Transition;

Transition.propTypes = {
  children: PropTypes.node.isRequired,
};
