import { Container, Row, Col } from "react-bootstrap";

import { BlockNumber } from "@/components/atoms/BlockNumber";
import Tooltip from "@/components/molecules/Tooltip";
import Pulse from "@/components/organisms/Web3/Pulse";
import Socials from "@/components/organisms/Socials";

import { useMedia } from "@/hooks/useMedia";

function Footer() {
  const isMobile = useMedia("(max-width: 991px)");
  return (
    <footer className="py-3">
      <Container>
        <Row>
          <Col
            xs={{ span: 4, offset: 4 }}
            className="d-flex justify-content-center"
          >
            <Socials />
          </Col>
          {!isMobile && (
            <Col
              xs={{ span: 4 }}
              className="d-flex justify-content-end align-items-center"
            >
              <small className="me-3">
                <Tooltip content={`Latest block on Ethereum!`} position="top">
                  <span className="user-select-none">
                    <BlockNumber chainId={1} />
                  </span>
                </Tooltip>
                <Pulse style={{ marginLeft: "12px" }} />
              </small>
            </Col>
          )}
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
