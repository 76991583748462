import { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useMedia } from "@/hooks/useMedia";

export const AsideContext = createContext();

export const AsideContextProvider = ({ children }) => {
  const [hasAside, setHasAside] = useState(false);
  const isMobile = useMedia("(max-width: 991px)");
  const toggleAside = () => setHasAside(!hasAside);

  useEffect(() => {
    if (!isMobile) setHasAside(false);
  }, [isMobile]);

  const providerValue = [hasAside, toggleAside];

  return (
    <AsideContext.Provider value={providerValue}>
      {children}
    </AsideContext.Provider>
  );
};

AsideContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
