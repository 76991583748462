import { Container, Row, Col } from "react-bootstrap";

import PropTypes from "prop-types";

function Main({ children }) {
  return (
    <main className="d-flex align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col xl={10}>{children}</Col>
        </Row>
      </Container>
    </main>
  );
}

export default Main;

Main.propTypes = {
  children: PropTypes.node.isRequired,
};
