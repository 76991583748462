import { useState, useEffect } from "react";

export const useMedia = (query: string): boolean => {
  const [value, setValue] = useState(() => matchMedia(query).matches);
  useEffect(() => {
    const handler = () => setValue(matchMedia(query).matches);
    window.addEventListener("resize", handler);
    return () => window.removeEventListener("resize", handler);
  }, [query]);
  return value;
};
