import styles from "./Connectors.module.scss";

import { useConnect } from "wagmi";

import Loader from "@/components/molecules/Loader";

function Connectors() {
  const { connect, connectors, isLoading, pendingConnector } = useConnect();

  return (
    <>
      {connectors.map((connector) => {
        return (
          <div
            onClick={() => !isLoading && connect({ connector })}
            role={!isLoading ? "button" : "none"}
            className={styles.connector}
            key={connector.id}
          >
            <div>
              <div className={styles.logo + " " + styles[connector.id]} />
              <span>{connector.name}</span>
              {connector.id === "metaMask" && (
                <small className="ms-2 text-muted">✩</small>
              )}
            </div>

            {isLoading && pendingConnector.id === connector.id && <Loader />}
          </div>
        );
      })}
      <div>
        <small className="text-muted">
          Icebox recommends MetaMask for the best experience.
        </small>
      </div>
      {/* { error && <div>{error.message}</div>} */}
    </>
  );
}

export default Connectors;
