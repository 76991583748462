import { useContext } from "react";
import { Modal } from "react-bootstrap";

import Connectors from "@/components/organisms/Web3/Connectors";
import Account from "@/components/organisms/Web3/Account";

import { ModalContext } from "@/context/ModalContext";

import { useConnect, useAccount } from "wagmi";

function ConnectModal() {
  const { hasModal, setModal } = useContext(ModalContext);

  const { isConnected } = useAccount();
  const { isLoading } = useConnect();

  let title, content;

  if (!isConnected) {
    title = "Connect your wallet";
    content = <Connectors />;
  } else {
    title = "Wallet connected";
    content = <Account />;
  }

  return (
    <Modal
      centered
      show={hasModal}
      onHide={() => setModal(false)}
      style={{ backgroundColor: "transparent" }}
    >
      {!isConnected && (
        <Modal.Header className="p-4">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body className="p-4">{content}</Modal.Body>

      {isLoading && (
        <Modal.Footer className="justify-content-center">
          <small className="text-muted">Connection request pending!</small>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default ConnectModal;
