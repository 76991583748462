import styles from "./Description.module.scss";

type DescProps = {
  text: string;
};

export const Description = ({ text }: DescProps): JSX.Element => (
  <span aria-label={text} role="description" className={styles.description}>
    {text.split("").map((char, i) => {
      return (
        <span aria-hidden="true" key={i}>
          {char}
        </span>
      );
    })}
  </span>
);
