import styles from "./Connect.module.scss";

import { useAccount, useNetwork, useSwitchNetwork, mainnet } from "wagmi";
import { useContext } from "react";
import { Button } from "react-bootstrap";

import { ModalContext } from "@/context/ModalContext";
import { Address } from "@/components/atoms/Address.ts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import { motion } from "framer-motion";

const Connect = () => {
  const { setModal } = useContext(ModalContext);
  const { switchNetwork } = useSwitchNetwork();
  const { isConnected } = useAccount();
  const { chain } = useNetwork();

  const isEth = chain?.id === mainnet.id;

  return (
    <div className="d-inline-flex justify-content-center align-items-center">
      {isConnected && (
        <div className="me-4 d-none d-lg-inline-block text-muted">
          {!isEth ? (
            <FontAwesomeIcon
              size="lg"
              role="button"
              icon={faExclamationTriangle}
              onClick={() => switchNetwork(1)}
            />
          ) : (
            <FontAwesomeIcon
              className="spinning-text"
              icon={faEthereum}
              size="lg"
            />
          )}
        </div>
      )}

      <Button
        className={`${styles.button} btn-animated`}
        onClick={() => setModal(true)}
      >
        {/* <div/> */}
        <motion.div
          whileHover={{
            scale: 1.2,
            transition: { duration: 1 },
          }}
          whileTap={{ scale: 0.9 }}
        />
        <span>{isConnected ? <Address /> : "CONNECT WALLET"}</span>
      </Button>
    </div>
  );
};

export default Connect;
